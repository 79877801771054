<template>
  <div id="app">
      <div class="app-con">
        <router-view />
        <div class="home-icon">
          <div @click="refresh" class="home-img shadow-game">
            <img src="./assets/img/logohead.jpg" style="width: 80px;border-radius: 50%;"alt="">
          </div>
        </div>
      </div>
      <div class="foot">
        <div>
          <div style="padding: 24px 20px 19px 20px;align-items: center;">
            <ul class="ul-outer">
              <li><span @click="goto('/aboutUs')">About Us</span></li>
              <li><span @click="goto('/privacyStatement')">Privacy Statement</span></li>
              <li><span @click="goto('/cookieStatement')">Cookie Statement</span></li>
              <li><span @click="goto('/termsofUse')">Terms of Use</span></li>
              <li><span @click="goto('/contact')">Contact</span></li>
              <li><span @click="goto('/faq')">FAQ</span></li>
            </ul>
            <div class="foot-icon">
              <div class="instagram" style="margin-right: 38px;" @click="gotoFaceBook('https://www.instagram.com')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                  <path class="instagram-logo" d="M22.5,13.5c2.9,0,3.3,0,4.4,0.1c1.1,0,1.7,0.2,2,0.4c0.5,0.2,0.9,0.4,1.3,0.8c0.4,0.4,0.6,0.8,0.8,1.3
                                    c0.2,0.4,0.3,1,0.4,2c0.1,1.2,0.1,1.5,0.1,4.4s0,3.3-0.1,4.4c0,1.1-0.2,1.7-0.4,2c-0.2,0.5-0.4,0.9-0.8,1.3
                                    c-0.4,0.4-0.8,0.6-1.3,0.8c-0.4,0.2-1,0.3-2,0.4c-1.2,0.1-1.5,0.1-4.4,0.1s-3.3,0-4.4-0.1c-1.1,0-1.7-0.2-2-0.4
                                    c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.4-0.3-1-0.4-2c-0.1-1.2-0.1-1.5-0.1-4.4s0-3.3,0.1-4.4
                                    c0-1.1,0.2-1.7,0.4-2c0.2-0.5,0.4-0.9,0.8-1.3c0.4-0.4,0.8-0.6,1.3-0.8c0.4-0.2,1-0.3,2-0.4C19.2,13.5,19.6,13.5,22.5,13.5
                                    M22.5,11.5c-3,0-3.4,0-4.5,0.1c-1.2,0.1-2,0.2-2.7,0.5c-0.7,0.3-1.3,0.7-1.9,1.3c-0.6,0.6-1,1.2-1.3,1.9c-0.3,0.7-0.5,1.5-0.5,2.7
                                    c-0.1,1.2-0.1,1.5-0.1,4.5c0,3,0,3.4,0.1,4.5c0.1,1.2,0.2,2,0.5,2.7c0.3,0.7,0.7,1.3,1.3,1.9c0.6,0.6,1.2,1,1.9,1.3
                                    c0.7,0.3,1.5,0.5,2.7,0.5c1.2,0.1,1.5,0.1,4.5,0.1s3.4,0,4.5-0.1c1.2-0.1,2-0.2,2.7-0.5c0.7-0.3,1.3-0.7,1.9-1.3
                                    c0.6-0.6,1-1.2,1.3-1.9c0.3-0.7,0.5-1.5,0.5-2.7c0.1-1.2,0.1-1.5,0.1-4.5s0-3.4-0.1-4.5c-0.1-1.2-0.2-2-0.5-2.7
                                    c-0.3-0.7-0.7-1.3-1.3-1.9c-0.6-0.6-1.2-1-1.9-1.3c-0.7-0.3-1.5-0.5-2.7-0.5C25.9,11.5,25.5,11.5,22.5,11.5L22.5,11.5z"></path>
                  <path class="instagram-logo" d="M22.5,16.9c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6C28.1,19.4,25.6,16.9,22.5,16.9z
                                    M22.5,26.2c-2,0-3.7-1.6-3.7-3.7s1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7S24.5,26.2,22.5,26.2z"></path>
                  </svg>
              </div>
              <div class="facebook" style="margin-right: 38px;" @click="gotoFaceBook('https://www.facebook.com')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                  <path class="facebook-logo" d="M22.5,11.6c-6.1,0-11,4.9-11,11c0,5.5,4,10,9.3,10.9v-7.7H18v-3.2h2.8v-2.4c0-2.8,1.6-4.3,4.2-4.3
                                c1.2,0,2.5,0.2,2.5,0.2v2.7H26c-1.4,0-1.8,0.8-1.8,1.7v2.1h3.1l-0.5,3.2h-2.6v7.7c5.3-0.8,9.3-5.4,9.3-10.9
                                C33.5,16.5,28.6,11.6,22.5,11.6z"></path>
                </svg>
              </div>
              <!-- <div style="width: 20px;"></div> -->
              <div class="tiktok" style="margin-right: 38px;" @click="gotoFaceBook('https://www.tiktok.com')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                  <path class="tiktok-logo" d="m30.6 16.7q-0.8-0.2-1.4-0.6-0.7-0.3-1.2-0.9-0.5-0.6-0.8-1.3-0.3-0.7-0.4-1.5v-0.4h-3.4v13.7c0 0.6-0.2 1.2-0.6 1.7-0.3 0.4-0.8 0.8-1.4 1-0.6 0.2-1.2 0.2-1.8 0-0.6-0.1-1.1-0.5-1.4-1-0.4-0.5-0.6-1.1-0.6-1.7 0-0.6 0.2-1.2 0.5-1.7 0.4-0.5 0.9-0.9 1.5-1.1 0.5-0.2 1.2-0.2 1.8 0v-3.5c-1.4-0.2-2.7 0.1-3.9 0.7-1.1 0.6-2.1 1.5-2.7 2.7-0.6 1.2-0.8 2.5-0.6 3.8 0.2 1.3 0.8 2.6 1.8 3.5 0.8 0.9 2 1.5 3.2 1.8 1.2 0.2 2.5 0.1 3.7-0.4 1.1-0.4 2.1-1.3 2.8-2.3 0.7-1 1.1-2.3 1.1-3.5v-7q0.5 0.4 1.1 0.6 0.6 0.3 1.2 0.5 0.6 0.2 1.2 0.3 0.7 0.1 1.3 0.1v-3.4q-0.1 0-0.3 0-0.1 0-0.2 0-0.1 0-0.3-0.1-0.1 0-0.2 0z"></path>
                </svg>
              </div>
              <!-- <div style="width: 20px;"></div> -->
              <div class="youtube" style="margin-right: 38px;" @click="gotoFaceBook('https://www.youtube.com')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                  <path class="youtube-logo" d="M33,17.2c-0.3-1-1-1.7-1.9-2c-1.7-0.5-8.6-0.5-8.6-0.5s-6.9,0-8.6,0.5c-0.9,0.3-1.7,1-1.9,2
                                  c-0.5,1.7-0.5,5.3-0.5,5.3s0,3.6,0.5,5.3c0.3,1,1,1.7,1.9,2c1.7,0.5,8.6,0.5,8.6,0.5s6.9,0,8.6-0.5c0.9-0.3,1.7-1,1.9-2
                                  c0.5-1.7,0.5-5.3,0.5-5.3S33.5,18.9,33,17.2z M20.2,25.8v-6.5l5.8,3.3L20.2,25.8z"></path>
                  </svg>
              </div>
              <div class="linkedin" @click="gotoFaceBook('https://www.linkedin.com')">
                <svg t="1726732253456" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4358" width="23" height="23"><path d="M385.024 706.368V406.72H285.376v299.68h99.616zM335.2 365.76c34.72 0 56.384-23.04 56.384-51.808-0.64-29.376-21.664-51.744-55.68-51.744-34.144 0-56.384 22.4-56.384 51.744 0 28.8 21.632 51.84 55.072 51.84z m409.024 340.608v-171.808c0-92.064-49.152-134.912-114.656-134.912-52.928 0-76.608 29.12-89.792 49.504v-42.496h-99.616c1.312 28.16 0 299.712 0 299.712h99.616v-167.36c0-8.96 0.64-17.92 3.264-24.256 7.168-17.92 23.584-36.448 51.072-36.448 36.064 0 50.56 27.456 50.56 67.744v160.352h99.584zM512 64c247.424 0 448 200.544 448 448 0 247.424-200.576 448-448 448-247.456 0-448-200.576-448-448C64 264.544 264.544 64 512 64z" fill="#181818" p-id="4359"></path></svg>            </div>
            </div>
            
          </div>
          
          <!-- <div class="shadow-game imgGames">
            <img style="width: 100%;height: 100%;" src="img/cc.jpg" alt="">
          </div> -->
        </div>

        <div style="display: flex;width: 1400px;margin: 0 auto;justify-content: space-between;">
          <div class="foot-bottom">© 2024 BLUEELECTRONIC INCORPORATED. All Rights Reserved.</div>
          <div class="foot-pay">
            <img src="./assets/img/DISCOVER.jpg" style="width: 90px;height: 46px;" alt="">
            <img src="./assets/img/logo_mastercard.webp" style="width: 105px;height: 46px;" alt="">
            <img src="./assets/img/paypal.jpg" style="width: 92px;height: 46px;" alt="">
            <img src="./assets/img/logo_visa.jpg" style="width: 90px;height: 46px;" alt="">
            <img src="./assets/img/American.jpg" style="width: 95px;height: 46px;margin: 0 10px;" alt="">
          </div>
        </div>

      </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import './style/app.css';
export default {
    name: 'app',
    data () {
        return {
          web: this.$utils.isDesktop(),
          isRefresh: true,
          dialogVisible: false,

        }
    },
    computed:{
		},
    methods: {
      ...mapMutations(['getnumGame']),
      handleOpen () {},
      handleClose () {},
      refresh () {
        this.$router.push('/')
        sessionStorage.setItem('numGame', '1')
        this.getnumGame()
      },
      goto (path) {
        this.$router.push(path)
      },
      gotoFaceBook (url) {
        window.open(url)
      },
    },
    mounted () {
    },
}
</script>
<style lang="less">
.foot {
  position: relative;
  bottom: 0;
  height: 190px;
  color: black;
  width: 100%;
  background: #efeded;
}
.ul-outer {
  width: 1200px;
  margin: 0 auto;
  font-size: 18px;
  text-decoration: underline;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  li {
    margin-right: 40px;
    span {
      cursor: pointer;
      color: black;
    }
  }
}
.foot-bottom {
  line-height: 50px;
  margin-right: 80px;
  font-size: 20px;
}
.home-icon {
  width: 1400px;
  margin: 0 auto;
  .home-img {
    position: fixed;
    top: 20px;
    width: 80px;
    height: 80px;
    margin-left: -34px;
    border-radius: 50%;
    background: #ecf5ff;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
.foot-icon {
  display: flex;
  justify-content: center;
  // margin-right: 450px;
  margin-top: 20px;
  div {
    width: 40px;
    height: 40px;
    border: 3px solid;
    border-radius: 25px;
  }
}
.instagram:hover {
  color: #ee4aaa;
  cursor: pointer;
  path {
    fill: #ee4aaa
  }
}
.facebook:hover {
  color: #1296db;
  cursor: pointer;
  path {
    fill: #1296db
  }
}
.linkedin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkedin:hover {
  cursor: pointer;
  color: rgb(19, 95, 236);
  path {
    fill: rgb(19, 95, 236);
  }
}
.tiktok:hover {
  cursor: pointer;
  color: rgb(9, 234, 241);
  path {
    fill: rgb(9, 234, 241);
  }
}
.youtube:hover {
  cursor: pointer;
  color: red;
  path {
    fill: red
  }
}
.app-con {
  min-height: calc(100vh - 190px);
  // overflow-x: hidden;
  background-color: #8abddb;
}
@media (min-width: 1400px) {
  .home-img {
    // margin-left: -70px;
  }
}
@media (max-width: 1400px) {
  .home-img {
      // margin-left: 10px;
  }
}
.foot-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    // margin: 0 26px;
  }
  // margin-bottom: 20px;
}
.foot-pay img {
  padding: 0 14px;
}
.imgGames {
  width: 150px;
  // height: 100px;
  position: absolute;
  right: 76px;
  bottom: 14px;
}
</style>
